import { NgModule } from '@angular/core';

import { PaginatedDatatableDirective } from './paginated-datatable.directive';
import { VarDirective } from './var.directive';

@NgModule({
  declarations: [VarDirective, PaginatedDatatableDirective],
  exports: [VarDirective, PaginatedDatatableDirective],
})
export class SharedDirectivesModule {}
