import { Directive, EventEmitter, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { DatatableComponent as NgxDatatableComponent } from '@swimlane/ngx-datatable';
import { Subscription } from 'rxjs';

export interface FetchDataRequest {
  limit?: number;
  order?: string[];
  descending?: boolean;
  cursor?: string;
}

interface Cursors {
  hasNext?: boolean;
  hasPrevious?: boolean;
  next?: string;
  previous?: string;
}

export interface FetchDataResponse<T> {
  data: T[];
  totalCount: number;
  cursors: Cursors;
}

interface DataSource<T> extends EventEmitter<[FetchDataResponse<T>, number | null]> {
  firstPage(request: FetchDataRequest | undefined): Promise<void>;
  nextPage(): Promise<void>;
  previousPage(): Promise<void>;
}

export function createDataSource<T>(fetchData: (request: FetchDataRequest) => Promise<FetchDataResponse<T>>) {
  const eventEmitter = new EventEmitter<[FetchDataResponse<T>, number | null]>();
  let lastRequest: FetchDataRequest | undefined;
  let lastResponse: FetchDataResponse<T> | undefined;
  let currentCursor: string | undefined;

  return {
    subscribe(...args) {
      return eventEmitter.subscribe(...args);
    },
    get lastRequest() {
      return lastRequest;
    },
    get lastResponse() {
      return lastResponse;
    },
    async firstPage(request: FetchDataRequest = lastRequest) {
      lastRequest = request;
      lastResponse = await fetchData({
        limit: request?.limit,
        descending: request?.descending,
        order: request?.order,
        cursor: undefined,
      });

      eventEmitter.emit([lastResponse, null]);
    },
    async reloadPage() {
      lastResponse = await fetchData({
        limit: lastRequest?.limit,
        descending: lastRequest?.descending,
        order: lastRequest?.order,
        cursor: currentCursor,
      });

      eventEmitter.emit([lastResponse, 0]);
    },
    async nextPage() {
      currentCursor = lastResponse?.cursors.next;

      lastResponse = await fetchData({
        limit: lastRequest?.limit,
        descending: lastRequest?.descending,
        order: lastRequest?.order,
        cursor: currentCursor,
      });

      eventEmitter.emit([lastResponse, 1]);
    },
    async previousPage() {
      currentCursor = lastResponse?.cursors?.previous;
      lastResponse = await fetchData({
        limit: lastRequest?.limit,
        descending: lastRequest?.descending,
        order: lastRequest?.order,
        cursor: currentCursor,
      });

      eventEmitter.emit([lastResponse, -1]);
    },
  };
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ngx-datatable[paginated]',
})
export class PaginatedDatatableDirective<T> implements OnInit, OnDestroy {
  @Input() dataSource: DataSource<T>;

  @HostBinding('class')
  elementClass = 'cursor-based-paging';

  private order;
  private descending?;
  private page = 0;

  private pageSub: Subscription;
  private sortSub: Subscription;
  private dataSub: Subscription;

  constructor(private datatable: NgxDatatableComponent) {}

  ngOnInit() {
    this.datatable.externalPaging = true;
    this.datatable.externalSorting = true;

    this.pageSub = this.datatable.page.subscribe((event) => {
      const isNext = event.offset > this.page;

      if (isNext) {
        this.dataSource.nextPage();
      } else {
        this.dataSource.previousPage();
      }
    });

    this.sortSub = this.datatable.sort.subscribe((event) => {
      this.page = 0;
      this.order = [event.sorts[0].prop];
      this.descending = event.sorts[0].dir === 'desc';

      this.dataSource.firstPage({
        limit: this.datatable.limit,
        order: this.order,
        descending: this.descending,
      });
    });

    this.dataSub = this.dataSource.subscribe(([{ data, totalCount }, page]) => {
      this.page = page === null ? 0 : this.page + page;
      this.datatable.offset = this.page;
      this.datatable.rows = data;
      this.datatable.count = totalCount;
    });
  }

  ngOnDestroy() {
    this.pageSub.unsubscribe();
    this.sortSub.unsubscribe();
    this.dataSub.unsubscribe();
  }
}
